import * as React from 'react'
import "../styles/form-dl.css"

const FormSentPage = () => {

  return (
    <div className="formDl">
      <div className="formDl__wrapper">

        <h2 className="formDl__heading">フォームが送信されました</h2>
        <div>
          <p className="formDl__text --center">資料をダウンロードいただきありがとうございます！<br />
            以下のURLより資料をダウンロードいただけます。<br /><br />
          </p>
          <p className="formDl__text --center">
            <a className='formDl__link' href=" https://drive.google.com/file/d/1pzSgL5Sa4W5KvfTxersWXqtVILQweQ4f/view?usp=sharing" target='_blank'> https://drive.google.com/file/d/1pzSgL5Sa4W5KvfTxersWXqtVILQweQ4f/view?usp=sharing</a>
          </p>

          <a className='formDl__button' href="https://jpholic.jp/ithojo/">TOPにもどる</a>
        </div>
      </div>
    </div >
  )
}
export default FormSentPage